<h2 mat-dialog-title class="dialog-title">Löschung bestätigen</h2>
<div mat-dialog-content class="dialog-content">
  <div class="dialog-content__item-info">
    <span>Sie versuchen Folgendes zu entfernen:</span>
    <p class="dialog-content__item-name">{{ data.itemName }}</p>
  </div>

  <p>Bedenken Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann.</p>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button [mat-dialog-close]="false">Abbrechen</button>

  <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>
    <mat-icon class="action-button-icon material-icons-outlined">delete</mat-icon>
    Löschung bestätigen
  </button>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  Signal,
  WritableSignal,
  computed,
  input,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';

import { ImageGalleryImage } from './image-gallery-image';
import { ImageGalleryViewerControllerComponent } from './image-gallery-viewer-controller/image-gallery-viewer-controller.component';
import { ImageGalleryOverlayComponent } from './overlay/image-gallery-overlay.component';

@Component({
  selector: 'mp-image-gallery-viewer',
  standalone: true,
  templateUrl: './image-gallery-viewer.component.html',
  styleUrl: './image-gallery-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, MatIconModule, ImageGalleryViewerControllerComponent],
})
export class ImageGalleryViewerComponent {
  @HostBinding('class') readonly class = 'mp-image-gallery-viewer';

  readonly overlay: InputSignal<boolean | undefined> = input<boolean | undefined>();

  readonly overrideMultipleImages: InputSignal<boolean | undefined> = input<boolean | undefined>();

  readonly alternateText: InputSignal<string | undefined> = input<string | undefined>();

  readonly mediaPath: InputSignal<string> = input<string>('static/');

  readonly images: InputSignal<ImageGalleryImage[]> = input.required<ImageGalleryImage[]>();

  protected readonly selectedImageIndex: WritableSignal<number> = signal<number>(0);

  protected readonly selectedImage: Signal<ImageGalleryImage | undefined> = computed(
    () => this.images()[this.selectedImageIndex()],
  );

  protected readonly imagePath: Signal<string> = computed(
    () => this.mediaPath() + (this.selectedImage()?.filename ?? ''),
  );

  protected hasError: WritableSignal<boolean> = signal<boolean>(false);

  constructor(private readonly dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(ImageGalleryOverlayComponent, {
      panelClass: 'image-gallery-overlay',
      data: {
        title: this.alternateText(),
        images: this.images(),
        mediaPath: this.mediaPath(),
        selectedImageIndex: this.selectedImageIndex(),
      },
    });
  }

  onErrorWhileLoadingImage(): void {
    this.hasError.set(true);
  }

  resetError(): void {
    this.hasError.set(false);
  }
}
